import React from 'react'
import Page from '../components/Page';

const NotFoundPage = ({ pageContext: { locale }}) => (
  <Page locale={locale}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Page>
)

export default NotFoundPage
